import React, { useEffect } from "react"
import { navigate } from "gatsby"
import data from "../data/fields"
import HeadGenerator from "../components/common/seo"
import summaryImage from "../images/summary_image.webp"

const NotFoundPage = () => {
  useEffect(() => {
    navigate(data.url.index)
  }, [])
  return <div></div>
}

export default NotFoundPage

export const Head = () => (
  <HeadGenerator
    data={{
      title: data.strings.seo.default.title,
      description: data.strings.seo.default.description,
      url: data.url.index,
      image: {
        srcWebp: summaryImage,
        widh: 1200,
        height: 630,
        altText: data.strings.seo.default.title,
      },
    }}
    largeSummaryImage
    goHome
  />
)
