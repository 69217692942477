import React from "react"

const HeadGenerator = ({ goHome, data, largeSummaryImage }) => (
  <>
    {/** Title */}
    <title>{data.title}</title>
    {/** Favicon */}
    <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="194x194" href="/favicon/favicon-194x194.png" />
    <link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-chrome-192x192.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
    <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#374151" />
    <link rel="shortcut icon" href="/favicon/favicon.ico" />
    <link rel="manifest" href="/site.webmanifest" />
    <meta name="msapplication-TileColor" content="#f9fafb" />
    <meta name="msapplication-config" content="/browserconfig.xml" />
    <meta name="theme-color" content="#f9fafb" />
    {/** Auto-redirect to home */}
    {Boolean(goHome) && <meta http-equiv="refresh" content="0; url=/" />}
    {/** Charset */}
    <meta charSet="utf-8" />
    {/** General meta tags */}
    <meta name="description" content={data.description} />
    <meta property="og:title" content={data.title} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={data.url} />
    {/** Twitter meta tags */}
    <meta name="twitter:card" content={largeSummaryImage ? "summary_large_image" : "summary"} />
    <meta name="twitter:title" content={data.title} />
    <meta name="twitter:description" content={data.description} />
    {/** SEO OpenGraph and Twitter image, if present */}
    {data.image && (
      <>
        <meta property="og:image" content={data.image.srcWebp} />
        <meta property="og:image:secure_url" content={data.image.srcWebp} />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content={data.image.width} />
        <meta property="og:image:height" content={data.image.height} />
        <meta property="og:image:alt" content={data.image.altText} />
        <meta name="twitter:image" content={data.image.srcWebp} />
      </>
    )}
    {/** More OpenGraph tags */}
    <meta property="og:title" content={data.title} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={data.url} />
    <meta property="og:description" content={data.description} />
    <meta property="og:locale" content="es_PE" />
    <meta property="og:site_name" content="Jockey On Ice" />
    {/** Allow site to be crawled */}
    <meta name="robots" content="follow" />
  </>
)

export default HeadGenerator
